export const COLORS = {
  MAIN: '#565695',
  BLACK: '#1C1C1C',
  LIGHT_GRAY: '#898a89',
  DARK_GRAY: '#404040',
  WHITE: '#FFFFFF',
  TRANSPARENT: 'transparent',
  REAL_BLACK: '#000000',
  ORANGE: 'orange',
  RED: 'red',
  BLUE: 'steelblue',
  LIGHT_PURPLE: '#9393BC',
};

export const FONTS = {
  NORMAL: 'NormalText',
  // NORMAL: 'Helvetica',
  MEDIUM: 'MediumText',
  BOLD: 'BoldText',
  SUPER_BOLD: 'SuperBoldText',
};

export const FONT_SIZE = {
  PX2: '0.125rem',
  PX4: '0.25rem',
  PX6: '0.375rem',
  PX8: '0.5rem',
  PX10: '0.625rem',
  PX12: '0.75rem',
  PX14: '0.875rem',
  PX16: '1rem',
  PX18: '1.125rem',
  PX20: '1.25rem',
  PX22: '1.375rem',
  PX24: '1.5rem',
  PX26: '1.625rem',
  PX28: '1.75rem',
  PX30: '1.875rem',
  PX32: '2rem',
  PX34: '2.125rem',
  PX36: '2.25rem',
  PX38: '2.375rem',
  PX40: '2.5rem',
  PX42: '2.625rem',
  PX44: '2.75rem',
  PX46: '2.875rem',
  PX48: '3rem',
  PX50: '3.125rem',
  PX52: '3.25rem',
  PX54: '3.375rem',
  PX56: '3.5rem',
  PX58: '3.625rem',
  PX60: '3.75rem',
  PX62: '3.875rem',
  PX64: '4rem',
  PX66: '4.125rem',
  PX68: '4.25rem',
  PX70: '4.375rem',
  PX72: '4.5rem',
  PX74: '4.625rem',
  PX76: '4.75rem',
  PX78: '4.875rem',
  PX80: '5rem',
  PX82: '5.125rem',
  PX84: '5.25rem',
  PX86: '5.375rem',
  PX88: '5.5rem',
  PX90: '5.625rem',
  PX92: '5.75rem',
  PX94: '5.875rem',
  PX96: '6rem',
  PX98: '6.125rem',
  PX100: '6.25rem',
};

export const PADDING = {
  XS: '0.25rem',
  SM: '0.5rem',
  MD: '0.75rem',
  LG: '1rem',
};

export const NO_SELECT = `
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `;

export const NO_SCROLLBAR = `
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  `;
