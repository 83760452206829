import React, { memo } from 'react';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';

import RedirectPage from '../RedirectPage/Loadable';
import HomePage from '../HomePage/Loadable';
import GlobalStyle from '../../global-styles';
import { COLORS } from '../../constants/styles';

const ST = {
  Wrapper: styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow: hidden;
    background-color: ${COLORS.BLACK};
  `,
  Body: styled.div`
    height: 100%;
    width: 100%;
  `,
};

const App = () => (
  <ST.Wrapper>
    <ST.Body>
      <Routes>
        <Route path={'/'} element={<HomePage />} />
        <Route path={'*'} element={<RedirectPage />} />
      </Routes>
    </ST.Body>
    <GlobalStyle />
  </ST.Wrapper>
);

App.defaultProps = {};
App.propTypes = {};

export default memo(App);
