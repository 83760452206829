import { createGlobalStyle } from 'styled-components';
import RalewayRegular from './fonts/Raleway-Regular.ttf';
import RalewayMedium from './fonts/Raleway-Medium.ttf';
import RalewayBold from './fonts/Raleway-Bold.ttf';
import RalewayBlack from './fonts/Raleway-Black.ttf';
import { FONTS } from './constants/styles';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
  }

  @font-face {
    font-family: NormalText;
    src: url(${RalewayRegular}) format("truetype")
  }

  @font-face {
    font-family: MediumText;
    src: url(${RalewayMedium}) format("truetype")
  }

  @font-face {
    font-family: BoldText;
    src: url(${RalewayBold}) format("truetype")
  }

  @font-face {
    font-family: SuperBoldText;
    src: url(${RalewayBlack}) format("truetype")
  }

  body {
    font-family: ${FONTS.NORMAL}, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: ${FONTS.NORMAL}, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    background-color: #ffffff;
    min-height: 100%;
    min-width: 100%;
    overflow: hidden;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }
`;

export default GlobalStyle;
